<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/reservation-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				Permissions
				<v-btn
					v-if="!edit_mode"
					v-on:click="edit_mode = true"
					class="float-right"
					color="blue darken-4 white--text"
					depressed
					tile
				>
					Edit Permissions
				</v-btn>
				<template v-else>
					<v-btn v-on:click="edit_mode = false" class="float-right" depressed tile> Cancel </v-btn>
					<v-btn
						class="float-right mx-2 custom-bold-button white--text"
						tile
						depressed
						v-on:click="update_permissions()"
						color="blue darken-4 white--text"
					>
						Save Permissions
					</v-btn>
				</template>
			</v-flex>
		</v-layout>
		<div class="overflow-y" style="max-height: calc(100vh - 306px)">
			<div class="bt-table listing-table">
				<table width="100%" style="table-layout: fixed">
					<thead>
						<tr>
							<th>Menu</th>
							<th width="100px" class="text-center">Create</th>
							<th width="100px" class="text-center">View</th>
							<th width="100px" class="text-center">Edit</th>
							<th width="100px" class="text-center">Delete</th>
							<th width="100px" class="text-center">Approve</th>
						</tr>
					</thead>
					<tbody> 
						<tr v-for="(row, key) in db_permissions" :key="key">
							<td height="53px" class="py-2">
								<div class="d-flex align-center">
									<!-- <span>
										<inline-svg width="15" height="15" :src="$assetURL(`media/custom-svg/${key}.svg`)" />
									</span> -->
									<span class=" text-capitalize fw-600">{{ get_label(key) }}</span>
								</div>
							</td>
							<td height="53px"   class="text-center py-2">
								<div v-if = "Object.keys(row).includes('create')" style="max-width: 100px" class="d-flex justify-content-center">
									<v-checkbox
										v-if="edit_mode"
										hide-details
										color="blue darken-4"
										v-model="row.create"
										class="mt-0 pt-0"
									></v-checkbox>
									<v-icon v-else large :color="row.create ? 'green darken-1' : 'red lighten-1'"
										>mdi-record</v-icon
									>
								</div>
							</td>
							<td height="53px"   class="text-center py-2">
								<div v-if = "Object.keys(row).includes('view')" style="max-width: 100px" class="d-flex justify-content-center">
									<v-checkbox
										v-if="edit_mode"
										hide-details
										color="blue darken-4"
										v-model="row.view"
										class="mt-0 pt-0"
									></v-checkbox>
									<v-icon v-else large :color="row.view ? 'green darken-1' : 'red lighten-1'"
										>mdi-record</v-icon
									>
									
								</div>
							</td>
							<td height="53px"   class="text-center py-2">
								<div v-if = "Object.keys(row).includes('edit')" style="max-width: 100px" class="d-flex justify-content-center">
									<v-checkbox
										v-if="edit_mode"
										hide-details
										color="blue darken-4"
										v-model="row.edit"
										class="mt-0 pt-0"
									></v-checkbox>
									<v-icon v-else large :color="row.edit ? 'green darken-1' : 'red lighten-1'"
										>mdi-record</v-icon
									>
									
								</div>
							</td>
							<td height="53px"  class="text-center py-2">
								<div v-if = "Object.keys(row).includes('delete')" style="max-width: 100px" class="d-flex justify-content-center">
									<v-checkbox
										v-if="edit_mode"
										hide-details
										color="blue darken-4"
										v-model="row.delete"
										class="mt-0 pt-0"
									></v-checkbox>
									<v-icon v-else large :color="row.delete ? 'green darken-1' : 'red lighten-1'"
										>mdi-record</v-icon
									>
									
								</div>
							</td>
							<td height="53px"  class="text-center py-2">
								<div v-if = "Object.keys(row).includes('approve')"  style="max-width: 100px" class="d-flex justify-content-center">
									<v-checkbox
										v-if="edit_mode"
										hide-details
										color="blue darken-4"
										v-model="row.approve"
										class="mt-0 pt-0"
									></v-checkbox>
									<v-icon v-else large :color="row.approve ? 'green darken-1' : 'red lighten-1'"
										>mdi-record</v-icon
									>
									
								</div>
							</td>



							
						</tr>
					</tbody>
				</table>

				

			</div>
		</div>
	</div>
</template>
<script>
import { SET_MESSAGE } from "@/core/services/store/common.module";
import { cloneDeep, size } from "lodash";
import ApiService from "@/core/services/api.service";

export default {
	name: "member-permission",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: Object,
			default() {
				return {};
			},
		},
		uuid: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			db_permissions: [],
			edit_mode: false,
		};
	},
	methods: {
		update_permissions() {
			ApiService.setHeader();
			ApiService.patch(`member/${this.uuid}/permissions`, { permissions: this.db_permissions })
				.then(() => {
					this.edit_mode = false;
					this.$emit("success", true);
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Permissions has been updated." },
					]);
				})
				.catch((error) => {
					this.$emit("error", true);
					console.log({ error });
				});
		},
		get_label(param) {
			if (param == "member") {
				return "Eng-Contractor";
			}
			return param;
		},
		object_size(param) {
			return size(param);
		},
		initialize() {
			this.db_permissions = cloneDeep(this.value);
		},
	},
	mounted() {
		this.initialize();
	},
};
</script>
